import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import { languageContext } from '../shared/context';
import { ProjectClass, ProjectContent } from '../shared/interfaces';
import { projectNameFormatter } from '../shared/utils/functions';

interface ProjectPageProps {
  projectName: string | null
}

export const Project = ({projectName}: ProjectPageProps) => {

  const{ content:{projects:{projects}} } = useContext(languageContext);
  const[project, setProject] = useState<ProjectContent>(new ProjectClass());
  const[currentSection, toggleCurrentSection] = useState<string | null>(null);
  const[lastSection, toggleLastSection] = useState<string | null>(null);
  const[transitioning, toggleTransitioningStatus] = useState<boolean>(false);
  const[initalMousePosition, setInitialMousePosition] = useState<number | null>(null);
  const[showSectionsList, toggleSectionsList] = useState<boolean>(false);
  const[innerWidth, updateInnerWidth] = useState<number>(window.innerWidth);
  const navigate = useNavigate();

  useEffect(() => {
    window.addEventListener('resize', () => {setSectionsList(); updateInnerWidth(window.innerWidth);});
  });

  useEffect(() => {
    projectName && setProject(projects.filter(x => x.name === projectName)[0]);
  }, [projectName, projects])
  

  useEffect(() => {
    setTimeout(() => {
      toggleCurrentSection('interface');
    }, 200);
  }, [])

  const setSectionsList = () => {
    toggleSectionsList(window.innerWidth > 1100 ? true : false);
  }

  const onScroll = (direction: string) => {
    if(!transitioning) {
      toggleTransitioningStatus(true);
      if(direction === 'down') {
        currentSection !== 'colors' && onChangeSection(currentSection as string, Object.keys(project.sections_names)[Object.keys(project.sections_names).indexOf(currentSection as string)+1]);
      } 
      else {
        currentSection !== 'interface' && onChangeSection(currentSection as string, Object.keys(project.sections_names)[Object.keys(project.sections_names).indexOf(currentSection as string)-1]);
      }
      setTimeout(() => {
        toggleTransitioningStatus(false);
      }, 500);
    }
  }

  const onChangeSection = (lastSection: string, currentSection: string) => {
    toggleCurrentSection(currentSection);
    toggleLastSection(lastSection);
    setTimeout(() => toggleLastSection(null), 800);
  }

  return (
    <div className='page project-page base-background d-flex justify-content-center align-items-center noselect'
    onMouseDown={e => setInitialMousePosition(e.clientY)}
    onTouchStart={e => setInitialMousePosition(e.touches[0].clientY)}
    onMouseUp={() => setInitialMousePosition(null)}
    onMouseLeave={() => setInitialMousePosition(null)}
    onMouseMove={e => initalMousePosition && onScroll(initalMousePosition - e.clientY >= 0 ? 'down' : 'up')}
    onTouchMove={e => initalMousePosition && onScroll(initalMousePosition - e.touches[0].clientY >= 0 ? 'down' : 'up')}
    onWheel={e => currentSection && onScroll(e.deltaY > 0 ? 'down' : 'up')}>
      <h1 className='page-title'>{project.name && projectNameFormatter(project.name)}</h1>
      <div className='container project-content d-flex flex-row align-items-center justify-content-center'>
        <div className='project-side-bar'
        onMouseDown={e => {e.stopPropagation();}}
        onTouchStart={e => {e.stopPropagation();}}
        onMouseUp={e => {e.stopPropagation();}}
        onMouseLeave={e => {e.stopPropagation();}}
        onMouseMove={e => {e.stopPropagation();}}
        onTouchMove={e => {e.stopPropagation();}}
        onWheel={e => {e.stopPropagation();}}>
         {currentSection && innerWidth <= 1100 &&
         <span className='selected-section' onClick={() => toggleSectionsList(!showSectionsList)}>{(project.sections_names as any)[currentSection].toUpperCase()}</span>}
          {(showSectionsList || innerWidth > 1100) && 
          <ul className='sections-list'>
            {project.sections_names && Object.keys(project.sections_names).map((section: string) => <li key={section} className={currentSection === section ? 'selected' : ''} 
            onClick={() => {toggleCurrentSection(section); innerWidth < 1100 && toggleSectionsList(false)}}>{(project.sections_names as any)[section].toUpperCase()}</li>)}
          </ul>}
        </div>
        <div className='project-section'>
          {[currentSection, lastSection].includes('interface') && <Section1 content={project.sections.interface} currentSection={currentSection} />}
          {[currentSection, lastSection].includes('logo') && <Section2 content={project.sections.logo} currentSection={currentSection} />}
          {[currentSection, lastSection].includes('brandmark') && <Section3 content={project.sections.brandmark} currentSection={currentSection} />}
          {[currentSection, lastSection].includes('colors') && <Section4 content={project.sections.colors} currentSection={currentSection} />}
        </div>
        <div className='project-side-bar'>
          <ul className='social-media-list'>
            {project.social && project.social.map(socialItem => 
            <li key={socialItem.type}>
              <a href={socialItem.link} target='_blank'><img src={socialItem.path}/></a>
            </li>)}
          </ul>
        </div>
      </div>
      <button className='back-to-projects-btn primary-btn' onClick={() => navigate('/projects')}>{project.back_btn}</button>
    </div>
  )
}

const Section1 = ({content, currentSection}: any) => {
  return (
    <div className={`section1 ${currentSection === 'interface' ? 'selected' : ''}`}>
      <img src={content} draggable={false} />
    </div>
  )
}

const Section2 = ({content, currentSection}: any) => {
  return (
    <div className={`section2 ${currentSection === 'logo' ? 'selected' : ''}`}>
      <img src={content} draggable={false} />
    </div>
  )
}

const Section3 = ({content, currentSection}: any) => {
  return (
    <div className={`section3 ${currentSection === 'brandmark' ? 'selected' : ''}`}>
      <img src={content} draggable={false} />
    </div>
  )
}

const Section4 = ({content, currentSection}: any) => {
  return (
    <div className={`section4 ${currentSection === 'colors' ? 'selected' : ''}`}>
      <img src={content} draggable={false} />
    </div>
  )
}