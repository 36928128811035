import React, { useContext, useEffect, useState } from 'react'
import { languageContext } from '../shared/context'

export const AboutUs = () => {
  const{ content:{about} } = useContext(languageContext);
  const[innerWidth, updateInnerWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', () => updateInnerWidth(window.innerWidth));
  },[])

  return (
    <div className='about-us page d-flex justify-content-center align-items-center'>
      <h3 className='page-title'>{about.title}</h3>
      <div className='container d-flex flex-row align-items-start justify-content-center'>
        {innerWidth > 1100 && <img src='/assets/images/innerhead-box.svg'/>}
        <div className='text'>
          <h2>{about.main_text}</h2>
          <p>{about.text}</p>
        </div>
      </div>
    </div>
  )
}
