import React, { useContext, useState } from 'react'
import { languageContext } from './context';

export const LanguagePicker = () => {

    const[currentLanguage, toggleCurrentLanguage] = useState('PT');
    const{ toggleLanguage } = useContext(languageContext);

    const onChangeLanguage = (language: string) => {
        toggleCurrentLanguage(language);
        toggleLanguage(language.toLowerCase());
    }

    const languages = ['PT', 'ENG']

  return (
      <>
        <div className='language-picker d-flex flex-row'>
            {
                languages.map((lang, index) => 
                    <div className='d-flex flex-row' key={index}>
                        <span className={currentLanguage === lang ? 'selected' : ''} onClick={() => onChangeLanguage(lang)}>{lang}</span>
                        {index < languages.length-1 && <span className={`separetor ${currentLanguage === lang ? 'selected' : ''}`}>|</span>}
                    </div>
                )
            }
        </div>
    </>
  )
}
