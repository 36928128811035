import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router';
import { AboutUs } from '../pages/about-us';
import { Contact } from '../pages/contact';
import { Home } from '../pages/home';
import { Project } from '../pages/project';
import { Projects } from '../pages/projects';
import { Content } from './interfaces';
import { Transition } from './transition';

interface PageProps {
    page: string,
    content: Content
}

export const Page = ({page}: PageProps) => {
    
    const[lastPage, toggleLastPage] = useState<string | null>(null);
    const[currentPage, toggleCurrentPage] = useState<string | null>(null);
    const[expanded, toggleExpanded] = useState(true);
    const location = useLocation();
    const { projectName } = useParams();
    const[storedProjectName, toggleStoredProjectName] = useState<string | null>(null);
    const routes = [
        {
            path: '/',
            component: <Home/>
        },
        {
            path: 'projects',
            component: <Projects/>
        },
        {
            path: 'about-us',
            component: <AboutUs/>
        },
        {
            path: 'contact',
            component: <Contact/>
        }
    ]

    useEffect(() => {
        toggleLastPage(currentPage);
        toggleCurrentPage(page);
        setTimeout(() => {
            toggleLastPage(null);
        }, 800);
        toggleExpanded(projectName || ['/', 'about-us', 'contact'].includes(page) ? true : false);
    }, [location])
    
    // useEffect(() => {
    //   if(projectName) {
    //       toggleStoredProjectName(projectName);
    //       setTimeout(() => {
    //         toggleLastPage(null);
    //         toggleCurrentPage(null); 
    //       }, 800);
    //   } else {
    //     setTimeout(() => {
    //         toggleStoredProjectName(null);
    //     }, 800);
    //   };
    // }, [projectName])

    return (
        <div className='all-content'>
            <Transition expanded={expanded}/>
            {
                routes.map((route, key) => 
                [lastPage, currentPage].includes(route.path) && 
                <div key={key} className={`page-container ${currentPage === route.path ? 'current-page' : 'last-page'}`}>
                    {route.component}
                </div>)
            }
            {/* {
              storedProjectName && 
              <div className={`page-container ${projectName ? 'current-page' : 'last-page'}`}>
                  <Project projectName={storedProjectName} />
              </div>
            } */}
        </div>
    )
}
