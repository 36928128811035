import React from 'react'

interface FloatingIconsProps {
  hideLeft?: boolean,
  hideRight?: boolean
}

export const FloatingIcons = ({hideLeft, hideRight}: FloatingIconsProps) => {
  return (
    <div className='floating-icons container d-flex flex-row align-items-end justify-content-between noselect'>
        {!hideLeft && <div className='left-icons d-flex flex-row'>
            <a href='https://www.linkedin.com/company/innerhead/' target='_blank'><span>IN</span></a>
            <a href='https://www.instagram.com/innerheadstudio/' target='_blank'><span>IG</span></a>
            {/* <a href='' target='_blank'><span>BE</span></a>
            <a href='' target='_blank'><span>DB</span></a> */}
        </div>}
        {!hideRight && <div className='right-icons'>
            <a href='https://api.whatsapp.com/send?phone=5511999349557&text=Ol%C3%A1!%20Em%20que%20posso%20ajud%C3%A1-lo%3F' target='_blank'><img src='/assets/icons/whatsapp.svg'/></a>
        </div>}
    </div>
  )
}
