import axios from 'axios';
import React, { useContext, useState } from 'react'
import { apiBaseUrl } from '../environment';
import { languageContext } from '../shared/context';

interface ContactForm {
  name: string,
  email: string,
  message: string
}

export const Contact = () => {
  const{ content:{contact} } = useContext(languageContext);
  const[sending, toggleSendingStatus] = useState(false);

  const[form, updateForm] = useState<ContactForm>({
    name: '',
    email: '',
    message: ''
  });

  const onSubmit = async () => {
    if(!sending && Object.keys(form).filter((key: string) => (form as any)[key] === '').length === 0) {
      try {
        toggleSendingStatus(true);
        let formData = new FormData();
        Object.keys(form).map((key: string) => formData.append(key, (form as any)[key]));
        await axios.post(`${apiBaseUrl}/contact.php`, formData);
        alert('Email enviado com sucesso!');
        toggleSendingStatus(false);
      } catch(e) {};
    }
  }

  return (
    <div className='contact page d-flex justify-content-center align-items-center'>
      <h3 className='page-title'>{contact.title}</h3>
      <div className='form-container'>
        <form>
          <div>
            <input name='name' placeholder={`${contact.name} *`} value={form.name} onChange={e => updateForm({...form, name: e.target.value})} />
          </div>
          <div>
            <input name='email' placeholder={`${contact.email} *`} value={form.email} onChange={e => updateForm({...form, email: e.target.value})} />
          </div>
          <div>
            <textarea name='message' placeholder={`${contact.message} *`} value={form.message} onChange={e => updateForm({...form, message: e.target.value})} />
          </div>
        </form>
        <button className='primary-btn' disabled={sending} onClick={onSubmit}>{contact.send_btn}</button>
      </div>
    </div>
  )
}
