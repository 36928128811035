import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { languageContext } from '../shared/context';

export const Home = () => {
  const navigate = useNavigate();
  const{ content:{home} } = useContext(languageContext);

  return (
    <div className='page home d-flex flex-column justify-content-center align-items-center'>
      <div className='d-flex flex-column align-items-center'>
        <img className='logo' src='/assets/images/innerhead-logo-type.svg'/>
        <p>{home.subtitle}</p>
      </div>
      <button className='primary-btn' onClick={() => navigate('/projects')}>{home.projects_btn}</button>
    </div>
  )
}
