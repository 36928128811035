import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from "react-router"
import { gsap } from "gsap"
import { languageContext } from '../shared/context';
import { projectNameFormatter } from '../shared/utils/functions';
import { Project } from './project';

let whellTimeout: NodeJS.Timeout;
let resizeTimeout: NodeJS.Timeout;

export const Projects = (props: any) => {
  const { projectName } = useParams();
  const{ content:{projects} } = useContext(languageContext);
  const navigate = useNavigate();
  const [projectsList, updateProjects] = useState<any[]>([]);
  const[beforeAnimations, toggleBeforeAnimations] = useState<boolean>(true);
  const[animations, updateAnimations] = useState<any[]>([]);
  const[currentProject, updateCurrentProject] = useState<number | null>(null);
  const[initalMousePosition, setInitialMousePosition] = useState<number | null>(null);
  const[projectBaseSize, changeProjectBaseSize] = useState<number>(120);
  const[margin, changeMargin] = useState<number>(250);
  const[windowWidth, updateWindowWidth] = useState<number>(window.innerWidth);
  const[storedProjectName, toggleStoredProjectName] = useState<string | null>(null);

  useEffect(() => {
    window.addEventListener('resize', () => updateWindowWidth(window.innerWidth));
  },[])

  const reset = () => {
    updateWindowWidth(window.innerWidth);
    updateCurrentProject(null);
    updateProjects([]);
    updateAnimations([]);
    clearTimeout(resizeTimeout);
    resizeTimeout = setTimeout(() => {
      setValues();
    }, 300);
  }

  useEffect(() => {
    if(projectName) {
        toggleStoredProjectName(projectName);
    } else {
      setTimeout(() => {
          toggleStoredProjectName(null);
      }, 800);
    };
  }, [projectName])

  const setValues = () => {
    let projectBaseSize = 80;
    let margin = 180;
    if (window.innerWidth > 1100) {
      projectBaseSize = 100;
      margin = 200;
    }
    if(window.innerWidth > 1400) {
      projectBaseSize = 120;
      margin = 250;
    }
    changeProjectBaseSize(projectBaseSize);
    changeMargin(margin);
    toggleBeforeAnimations(true);
    let projectsList = [...projects.projects, ...projects.projects];
    updateProjects(projectsList.map((x, i) => ({...x, id: i+1, left: 0})));
    updateAnimations(projectsList.map(() => gsap.timeline()));
  }

  useEffect(() => {
    !projectsList.length && setValues();
  }, [projects])

  useEffect(() => {
    if(animations.length > 0 && beforeAnimations) {
      setTimeout(() => {
        setProjectsPositions();
      });
      toggleBeforeAnimations(false);
    }
  }, [animations])
  

  const setProjectsPositions = () => {
    updateProjects(projectsList.map((x, i) => {
      x.left = ((projectBaseSize + margin) * i);
      animations[i].to(`#project_${x.id}`, { duration: 0, x: x.left });
      return x;
    }));
    setTimeout(() => {
      // let i = 0;
      // let interval = setInterval(() => {
      //   if(i <= -((window.innerWidth/2)-(projectBaseSize/2))) {
      //     clearInterval(interval);
      //     repositionProjects(-((window.innerWidth/2)-(projectBaseSize/2)));
      //     setTimeout(() => {
      onMoveStop();
      //     }, 400);
      //   } else {
      //     i-=(((window.innerWidth/2)-(projectBaseSize/2)));
      //     repositionProjects(-((window.innerWidth/2)-(projectBaseSize/2)), 0);
      //   }
      // });
    });
  }

  const onMove = (e: any) => {
    if(initalMousePosition) {
      clearTimeout(whellTimeout);
      repositionProjects((initalMousePosition - e.clientX)/(window.innerWidth > 1100 ? 10 : 1.2));
      whellTimeout = setTimeout(() => {
        onMoveStop();
      }, 400);
    };
  }

  const onWhell = (e: any) => {
    clearTimeout(whellTimeout);
    repositionProjects(e.deltaY);
    whellTimeout = setTimeout(() => {
      onMoveStop();
    }, 400);
  }

  const onMoveStop = () => {
    setInitialMousePosition(null);
    let baseOffsetLeft = ((window.innerWidth/2)-(projectBaseSize/2));
    for(let x = 0; x < projectsList.length; x++) {
      if(projectsList[x].left >= baseOffsetLeft - margin && projectsList[x].left <= baseOffsetLeft + margin) {
        repositionProjects(projectsList[x].left - baseOffsetLeft);
        updateCurrentProject(x+1);
      }
    };
  }

  const centerProject = (projectIndex: number) => {
    let baseOffsetLeft = ((window.innerWidth/2)-(projectBaseSize/2));
    repositionProjects(projectsList[projectIndex].left - baseOffsetLeft);
    updateCurrentProject(projectIndex+1);
  }

  const repositionProjects = (baseOffsetLeft: number, duration: number = 1) => {
    let copy_projects = [...projectsList];
    copy_projects.map((x, i) => {
      let left = 0;
      animations[i].clear();
      if(x.left - baseOffsetLeft <= window.innerWidth + (projectBaseSize * 4) && x.left - baseOffsetLeft >= -(projectBaseSize * 4)) {
        left = x.left - baseOffsetLeft;
        animations[i].to(`#project_${x.id}`, { duration, x: left, ease: 'power4' });
      } else {
        if(x.left - baseOffsetLeft > window.innerWidth + (projectBaseSize * 4)) {
          left = (x.left - baseOffsetLeft) - ((projectBaseSize + margin) * projectsList.length-1);  
        } 
        else if(x.left - baseOffsetLeft < -(projectBaseSize * 4)) {
          left = (x.left - baseOffsetLeft) + ((projectBaseSize + margin) * projectsList.length-1);
        };        
        animations[i].to(`#project_${x.id}`, { duration: 0, x: left });
      }
      x.left = left;
      updateProjects(copy_projects);
      return x;
    })
  }

  return (
    <>
    <div className={`page-container ${!projectName ? 'current-page' : 'last-page'}`}>
        <div className='page projects d-flex justify-content-center align-items-center'
        onMouseDown={e => setInitialMousePosition(e.clientX)}
        onMouseUp={() => setInitialMousePosition(null)}
        onMouseLeave={() => setInitialMousePosition(null)}
        onMouseMove={onMove}
        onWheel={e => onWhell(e)}
        onTouchStart={e => setInitialMousePosition(e.touches[0].clientX)}
        onTouchMove={e => onMove({clientX: e.touches[0].clientX})}>
          <h3 className='page-title'>{projects.title}</h3>
          <div className='projects-content-container noselect'>
            <div className='projects-content noselect'>
              <div className='projects-list d-flex flex-row align-items-center noselect'>
                {
                  projectsList.map((project, key) => {
                    return <div id={`project_${project.id}`} key={key}
                            onClick={() => currentProject === project.id ? navigate(`/projects/${project.name}`) : centerProject(key)}
                            className={`project ${project.id} ${currentProject && currentProject == project.id ? 'expanded' : ''}`}
                            style={{
                              width: projectBaseSize+'px',
                              height: projectBaseSize+'px'
                            }}>
                              <img className={`big`} draggable={false} src={`/assets/images/projects/${project.name}/logo-expanded.svg`}/>
                              <img className={`small`} draggable={false} src={`/assets/images/projects/${project.name}/logo.svg`}/>
                          </div>
                  })
                }
              </div>
              {
                windowWidth <= 1100 &&
                <div className='projects-dots'>
                  {
                    projectsList.map((x, i) => <div key={x.id} className={x.id === currentProject ? 'dot selected' : 'dot'} onClick={() => centerProject(i)}></div>)
                  }
                </div>
              }
            </div>
          </div>
          {currentProject && 
          <div className='select-project-info d-flex flex-column'>
            {windowWidth > 1100 &&
            <>
              <p className='text-center'>{projectsList.filter(x => x.id === currentProject)[0].type.toUpperCase()}</p>
              <h3 className='text-center fw-bold mb-3'>{projectNameFormatter(projectsList.filter(x => x.id === currentProject)[0].name)}</h3>
            </>}
            <button className='primary-btn d-flex align-self-center' onClick={() => navigate(`/projects/${projectsList.filter(x => x.id === currentProject)[0].name}`)}>{projects.explore_btn}</button>
          </div>}
        </div>
      </div>
      {
        storedProjectName && 
        <div className={`page-container ${projectName ? 'current-page' : 'last-page'}`}>
            <Project projectName={storedProjectName} />
        </div>
      }
    </>
  )
}