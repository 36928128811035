import React, { useEffect, useState } from 'react'

export const Transition = ({expanded}: any) => {

    const[size, updateSize] = useState(4);

    useEffect(() => {
      let i = size;
      if(!expanded) {
        let interval = setInterval(() => {
          i-=(window.innerWidth < 1100 ? 0.05 : 0.02);
          updateSize(i);
          if(i <= (window.innerWidth < 1100 ? 0.8 : 1)) {
            clearInterval(interval);
          }
        });
      } else {
        let interval = setInterval(() => {
          i+=0.02;
          updateSize(i);
          if(i >= 4) {
            clearInterval(interval);
          }
        });
      }
    }, [expanded])

    return (
      <div className="transition">
        <div className='circle-1 circle' style={{
          transition: `all ${size * 1/ 10}s cubic-bezier(0.1, 0.24, 0.04, 1.09) 0s`,
          transform: `translateX(-50%) translateY(-50%) scale(${size * 30 / 100})`
        }}></div>
        <div className='circle-2 circle' style={{
          transition: `all ${size * 2/ 10}s cubic-bezier(0.1, 0.24, 0.04, 1.09) 0s`,
          transform: `translateX(-50%) translateY(-50%) scale(${size * 50 / 100})`
        }}></div>
        <div className='circle-3 circle' style={{
          transition: `all ${size * 4/ 10}s cubic-bezier(0.1, 0.24, 0.04, 1.09) 0s`,
          transform: `translateX(-50%) translateY(-50%) scale(${size * 70 / 100})`
        }}></div>
        <div className='circle-4 circle' style={{
          transition: `all ${size * 6/ 10}s cubic-bezier(0.1, 0.24, 0.04, 1.09) 0s`,
          transform: `translateX(-50%) translateY(-50%) scale(${size * 90 / 100})`
        }}></div>
      </div>
    );
}
