import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.scss';
import { Transition } from './shared/transition';
import { Route, Routes } from 'react-router-dom';
import { Home } from './pages/home';
import { Page } from './shared/page';
import { useNavigate } from "react-router-dom";
import { Header } from './shared/header';
import { FloatingIcons } from './shared/floating-icons';
import { languageContext } from './shared/context';
import portugueseLang from './languages/pt.json';
import englishLang from './languages/eng.json';
import chinesseLang from './languages/ch.json';
import { Content, ContentClass } from './shared/interfaces';

function App() {

  const[language, toggleLanguage] = useState<string>('pt');
  const[content, toggleContent] = useState<Content>(new ContentClass());
  const[windowWidth, updateWindowWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    setLanguageContent('pt');
    window.addEventListener('resize', () => updateWindowWidth(window.innerWidth));
  },[])

  useEffect(() => {
    setLanguageContent(language);
  },[language])

  const setLanguageContent = (language: string) => {
    const languages: any = {
      pt: portugueseLang,
      eng: englishLang,
      ch: chinesseLang
    };
    toggleContent(languages[language]);
  };

  return (
    <languageContext.Provider value={{ language, content, toggleLanguage }}>
      <div className="App" style={{
        height: window.innerHeight+'px'
      }}>
        <Header/>
        <Routes>
          <Route path='/' element={<Page page='/' content={content} />}/>
          <Route path='projects' element={<Page page='projects' content={content} />}/>
          <Route path='projects/:projectName' element={<Page page='projects' content={content} />}/>
          <Route path='about-us' element={<Page page='about-us' content={content} />}/>
          <Route path='contact' element={<Page page='contact' content={content} />}/>
        </Routes>
        <FloatingIcons hideLeft={windowWidth < 1100}/>
      </div>
    </languageContext.Provider>
  );
}

export default App;
