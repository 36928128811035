import { createContext } from "react";
import { Content, ContentClass } from "./interfaces";

interface LanguageContext {
    language: string, 
    content: Content, 
    toggleLanguage: Function
}

export const languageContext = createContext<LanguageContext>({
    language: 'pt',
    content: new ContentClass(),
    toggleLanguage: () => {}
});