import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { LanguagePicker } from './language-picker';
import { Menu } from './menu';

export const Header = () => {
  const navigate = useNavigate();
  const[showMenu, toggleMenu] = useState(false);
  const[menuClass, toggleMenuClass] = useState('innerhead-logo');
  const[logo, toggleLogo] = useState<string>();
  const[windowWidth, updateWindowWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    setLogo();
    window.addEventListener('resize', () => {setLogo(); updateWindowWidth(window.innerWidth);});
  }, [])

  const setLogo = () => {
    toggleLogo(window.innerWidth > 1100 ? 'innerhead-logo' : 'innerhead-logo-mobile');
  }

  const onToggleMenu = () => {
    if(menuClass === 'toggle-up') {
      toggleMenuClass('');
      setTimeout(() => {
        toggleMenu(false);
      }, 400);
    } else {
      toggleMenu(true);
      setTimeout(() => {
        toggleMenuClass('toggle-up');
      });
    }
  }
  

  return (
    <>
      <div className='header'>
          <div className='container d-flex flex-row justify-content-between align-items-start'>
              <img className='logo' src={`../../assets/images/${logo}.svg`} onClick={() => navigate('/')} />
              <div className='header-right-side-content d-flex flex-row align-items-center'>
                {windowWidth > 1100 && <LanguagePicker/>}
                <div className={`menu-icon ${menuClass === 'toggle-up' ? 'opened' : 'closed'} ms-5`} onClick={onToggleMenu}>
                  <span></span>
                  <span></span>
                </div>
              </div>
          </div>
      </div>
      {showMenu && <Menu menuClass={menuClass} onClose={onToggleMenu} />}
    </>
  )
}
