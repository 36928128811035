import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router"
import { Link } from "react-router-dom"
import { languageContext } from "./context";
import { FloatingIcons } from "./floating-icons";
import { LanguagePicker } from "./language-picker";

interface MenuProps {
  menuClass: string,
  onClose: Function
}

export const Menu = ({menuClass, onClose}: MenuProps) => {
  const location = useLocation();
  const{ content:{menu} } = useContext(languageContext);
  const[windowWidth, updateWindowWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', () => updateWindowWidth(window.innerWidth));
  }, [])

  return (
    <div className={`menu base-background ${menuClass} d-flex justify-content-center align-items-center`}>
      {windowWidth <= 1100 && <LanguagePicker/>}
      <div className="links d-flex flex-row align-items-center">
        {
          Object.keys(menu).map((key: string) => 
          <li key={key} onClick={() => onClose()}
          className={`${(location.pathname === (menu as any)[key] || 
          (location.pathname.includes('projects') && (menu as any)[key] === '/projects')) ? 'fw-bold' : ''}`}>
            <Link to={(menu as any)[key]}>{key}</Link>
          </li>)
        }
      </div>
      {windowWidth < 1100 && <FloatingIcons hideRight={true}/>}
    </div>
  )
}
