interface SocialLinks {
    type: string,
    path: string,
    link: string
}

export interface ProjectContent {
    name: string,
    type: string,
    sections: {
        interface: string,
        logo: string,
        brandmark: string,
        colors: string
    },
    social: SocialLinks[],
    id?: number,
    left?: number,
    sections_names: {
        interface: string,
        logo: string,
        brandmark: string,
        colors: string
    },
    back_btn?: string
}

interface HomeContent {
    subtitle: string,
    projects_btn: string
}

interface ProjectsContent {
    title: string, 
    explore_btn: string,
    projects: ProjectContent[]
}

interface AboutUsContent {
    title: string,
    main_text: string,
    text: string
}

interface ContactContent {
    title: string,
    name: string,
    email: string,
    message: string,
    send_btn: string
}

interface MenuContent {
    home: string,
    projects: string,
    about: string,
    contact: string
}

export interface Content {
    home: HomeContent,
    projects: ProjectsContent,
    contact: ContactContent,
    about: AboutUsContent,
    menu: MenuContent
}
  
export class ContentClass implements Content {
    home: any
    projects: any
    contact: any
    about: any
    menu: any
}

export class ProjectClass implements ProjectContent {
    name: any
    type: any
    sections: any
    social: any
    sections_names: any
}